import { app_route_ids, app_route_keys } from "constants/urlPaths";
import api from "./APIs";

export const apiMethods = {
  GET: "GET",
  POST: "POST",
  DELETE: "DELETE",
};

export const HTTP_STATUS_SUCCESS_CODES = {
  HTTP_STATUS_OK: 200,
  HTTP_STATUS_CREATED: 201,
  HTTP_STATUS_ACCEPTED: 202,
  HTTP_STATUS_NON_AUTHORITATIVE_INFORMATION: 203,
  HTTP_STATUS_NO_CONTENT: 204,
  HTTP_STATUS_RESET_CONTENT: 205,
  HTTP_STATUS_PARTIAL_CONTENT: 206,
  HTTP_STATUS_MULTI_STATUS: 207,
  HTTP_STATUS_ALREADY_REPORTED: 208,
  HTTP_STATUS_IM_USED: 226,
};

// TODO remove from here and move to feature.apis.js
export const answers_export_resources = [
  app_route_ids[app_route_keys.listing_customer],
  "payments/exly/merchandise/bookings",
  "payments/exly/recorded/bookings",
  api.web_leads,
  app_route_ids[app_route_keys.subscription_customer],
];

//
export const use_db_index = [
  "Blogs",
  "payments/exly/merchandise/bookings",
  "host/v2/interest/list",
  // app_route_ids[app_route_keys.ledger],
];

// these are error codes used by backend interally
export const INTERNAL_STATUS_CODES = {
  EXPIRED_CREDIT_STATUS_CODE: 433,
  NOT_MODIFIED_STATUS_CODE: 304,
};

export const JWT_GRANT_TYPES = {
  NEW_AUTHORIZATION_CODE: "new_authorization_code",
  AUTHORIZATION_CODE: "authorization_code",
};
