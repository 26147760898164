import { ALL_CUSTOMERS_RESPONSE_API } from "features/Crm/modules/AllCustomers/utils/AllCustomers.responseApi";
import { MANAGE_BOOKINGS_RESPONSE_APIS } from "features/Crm/modules/Bookings/modules/ManageBookings/utils/ManageBookings.responseApi";
import { MY_BOOKINGS_RESPONSE_API } from "features/Crm/modules/Bookings/modules/MyBookings/utils/MyBookings.responseApi";
import { TRANSACTIONS_RESPONSE_APIS } from "features/Crm/modules/Transactions/utils/Transactions.responseApi";

// below object represent the resource for which data manipulation needs to be performed
export const CUSTOM_RESPONSE_APIS = {
  ...ALL_CUSTOMERS_RESPONSE_API,
  ...MANAGE_BOOKINGS_RESPONSE_APIS,
  ...TRANSACTIONS_RESPONSE_APIS,
  ...MY_BOOKINGS_RESPONSE_API,
};
