const resources = {
  // TODO: implement all resource keys from this enum
  reports: "Reports",
  team: "team",
  blogs: "Blogs",
  referrals: "Referrals",
  schedule_emails: "scheduled_emails",
  past_emails: "past_emails",
  whatsapp_campaigns: "whatsapp_campaigns",
};
export default resources;
