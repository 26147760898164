import { listing_apis } from "features/Listings/Listings.constants";

const api = {
  recorded_content_list: "host/recordedcontent/list",
  recorded_content_detail: "host/recordedcontent/details/",
  recorded_content_update: "host/recordedcontent/update",
  recorded_content_delete: "host/recordedcontent/delete",
  recorded_content_create: "host/recordedcontent/submit",
  check_sku: "host/sku/available",
  leads_msgs: "host/v2/interest/view",
  fonts_list: "host/fonts/list",
  create_custom_code: "host/custom/code/create",
  update_custom_code: "host/custom/code/update",
  offline_payment: "payments/exly/offline/payment",
  dashboard_leads: "host/lead/analytics",
  dashboard_lead_graph: "host/lead/analytics/graph",
  fetch_offerings: "host/listing/paginated/list",
  fetch_discount_listings: "payments/exly/discount/listings/list",
  get_Listing_broadcast_data: "notifications/exly/listing/broadcast/values",
  content_purchases_list: "payments/exly/purchased/listings/list",
  content_expiry_update: "payments/exly/booking/expiry/update",
  customer_activity: "payments/exly/activity",
  check_google_integration: "users/calendar/validity",
  get_google_calendar_backsync: "users/get/calendar/backsync",
  check_zoom_integration: "users/check/externalmeet/integration",
  generate_meet_link: "users/calendar/meet/generate",
  google_sync: "users/calendar/sync",
  calendar_initiate: "users/calendar/validation/initiate",
  get_referral: "users/referral/url",
  send_referral_mail: "users/send/referral",
  get_referral_overview: "users/creator/referral/overview",
  get_referral_list: "users/creator/referrals/list",
  get_onboarding_content: `content/v2/exly/onboarding`,
  web_leads: "host/v2/interest/list",
  ad_leads: "host/adleads/list",
  campaigns_leads: "campaignsLeads",
  campaigns_customers: "campaignsCustomers",
  update_webpage_lead: "host/update/lead/status",
  update_listing: "host/v2/listing/update",
  submit_listing: "host/v2/listing/submit",
  send_automated_test_msg: "notifications/test/communication",
  get_past_email: "notifications/email/list",
  send_email: "notifications/email/send",
  merchandise_booking_update: "payments/exly/merchandise/bookings/update",

  get_brand_partnerships: "content/listing/brand",
  add_brand_interest: "content/interest/brand",
  check_availability: "users/domain/check_availability",
  create_new_user: "users/create/email",
  call_existing_user: "users/login/email",
  post_google_login: "users/auth",
  get_session_participants: "host/slot/participants/list",
  get_host_listings_upcomming: "host/listing/upcoming",
  get_customer_slots: "host/customer/slots/data",
  get_schedule_checklist: "host/checklist",
  check_calendar_validity: "users/calendar/validity",
  submit_cancellation_policy: "users/cancellation/update",
  add_suggestion: "host/suggestion/add",
  create_discount: "payments/exly/discounts/create",
  delete_discount: "payments/exly/discounts/delete",
  fetch_gallery: "users/gallery/list",
  create_gallery: "users/gallery/insert",
  update_gallery: "users/gallery/update",
  delete_gallery: "users/gallery/delete",
  reorder_gallery: "users/gallery/reorder",
  add_listing_review: "host/listing/review/add",
  fetch_testimonial: "users/testimonial/list",
  create_testimonial: "users/testimonial/insert",
  update_testimonial: "users/testimonial/update",
  delete_testimonial: "users/testimonial/delete",
  reorder_testimonial: "users/testimonial/reorder",
  fetch_feature: "users/feature/list",
  create_feature: "users/feature/insert",
  update_feature: "users/feature/update",
  delete_feature: "users/feature/delete",
  reorder_feature: "users/feature/reorder",
  fetch_statistic: "users/statistic/list",
  create_statistic: "users/statistic/insert",
  update_statistic: "users/statistic/update",
  delete_statistic: "users/statistic/delete",
  reorder_statistic: "users/statistic/reorder",
  generate_payment_link: "payments/exly/link/generate",
  check_listing_conflict: "host/listing/conflict/check",
  add_listing: "host/listing/submit",
  update_listing_v1: "host/listing/update",
  duplicate_listing: "host/listing/duplicate",
  delete_listing: "host/listing/delete",
  initiate_refund: "payments/exly/refunds/initiate",
  user_payout_details: "users/payout/details",
  cancel_subscription: "payments/exly/subscription/cancel",
  calendar_validate: "users/calendar/validation/initiate",
  get_affiliate_partnerships: "content/listing/affiliate",
  add_affiliate_interest: "content/interest/affiliate",
  menu_permissions: "users/menu/permissions",
  user_referral_details: "payments/exly/user/referrals/details",
  upload_report: "content/report/upload",
  list_reports: `content/report/list`,
  get_recordedcontent_details: "host/v2/recordedcontent/details/",
  publish_listing: "host/v2/listing/publish",
  questions: {
    add_questions: "host/question/add",
    update_questions: "host/question/update",
    delete_question: "host/question/delete",
    get_questions: "host/question/list",
  },

  tap_affiliate: "users/tapfiliate/click",
  generate_zoom_link: "users/generate/zoom/link",
  get_staffs: "users/get/staff",
  add_staff: "users/add/staff",
  update_user: "users/update/",
  update_staff: "users/update/staff",
  get_audiance_list: "payments/exly/loyalty/audience/list",
  get_post_audiance_list: "payments/exly/loyalty/customers/list",
  get_creator_page_details: "users/v2/creator/details",
  transaction_answers: "payments/exly/answers",
  my_bookings_view_details: "payments/exly/transaction/detail",
  lead_answers: "host/v2/interest/answers",
  handle_blog: "blogs/posts/",
  get_template_list: "host/templates/list",
  get_template_details: "host/templates/details/",
  update_selected_theme: "host/theme/update",
  post_unavailability: "host/unavailability/update",
  get_unavailability: "host/unavailability/fetch",
  check_booking_unavailability: "host/unavailability/conflict/check",
  faq_list: "host/exly/faq/list",
  faq_insert: "host/exly/faq/insert",
  faq_update: "host/exly/faq/update",
  faq_delete: "host/exly/faq/delete",
  update_listing_feedbacks: "host/exly/listing/feedback/update",
  delete_staff: "users/delete/staff",
  get_offers: "host/offer/list",
  delete_scheduled_mail: "notifications/delete/scheduled/mail",
  delete_draft_email: "notifications/exly/email_draft/delete",
  group_emails: "notifications/get/group/emails",
  task_emails: "notifications/task/emails",
  email_sections: "notifications/email/sections/v2",
  email_suggestions: "notifications/email/suggestions",
  schedule_email: "notifications/edit/scheduled/mail",
  email_draft_update: "notifications/email_draft/update",
  email_draft_save: "notifications/exly/email_draft/save",
  get_recepients: "notifications/exly/email/recepients",
  get_email_recepients: "notifications/exly/custom/recipeints",
  get_listing_coupons: "payments/exly/discount/listview",
  fetch_draft_emails: "notifications/email_draft/list",
  get_listing_details: listing_apis.get_listing_details,
  get_listing_variants: "host/booking/variants",
  fetch_wistia_access_token: "users/wistia/token/fetch",
  fetch_wistia_refresh_token: "users/wistia/token/refresh",
  get_sku_list: "host/attendance/sku/list",
  get_sku_student_list: "host/attendance/sku/student/list",
  mark_attendance: "host/attendance/student/mark",
  get_single_sku_info: "host/attendance/sku/detail",
  mark_all_attendance: "host/attendance/student/all/mark",
  get_student_attendance_calendar: "host/attendance/student/calendar",
  send_payment_link: "host/attendance/student/reminder",
  get_another_batch_students: "host/attendance/student/other/list",
  add_another_batch_students: "host/attendance/student/add",
  get_attendance_analytics_details: "host/attendance/all/detail",
  get_attendance_analytics_list: "host/attendance/student/all/list",
  update_student_attendance_expiry: "host/attendance/student/expiry/update",
  update_sku_settings: "host/attendance/sku/update",
  get_plans: "host/plans",
  add_staff_member: "users/add/staff",
  get_organisation_members: "users/get/org/members",
  get_member_details: "users/staff/details",
  get_organisation_stats: "users/staff/stats",
  get_upcoming_schedules: "host/upcoming/org/schedules",
  get_organisation_logs: "users/staff/logs",
  assign_listings: "users/assignee/staff/listing",
  get_role_description: "users/roles/description",
  get_mavplead_data: "host/fetch/mvplead/data",
  get_assignee_list: "users/staff/assignee/list",
  webpush_update_seen: "notifications/exly/webpush/updateseen",
  assign_lead_to_staff: "users/assignee/leads",
  get_dashboard_banner: "content/exly/banner",
  get_notification_status: "notifications/exly/webpush/status",
  leads_upload: "host/leads/upload",
  customers_upload: "host/customers/upload",
  get_custom_invoice: "payments/receipt/payment",
  update_invoice_user_details: "users/creator/invoice/update",
  create_certificate: "host/certificate/create",
  get_certificates: "host/certificate/listing",
  get_certificate_details: "host/certificate/details",
  update_certificate: "host/certificate/update",
  get_certificate_template: "host/certificate",
  get_creator_comms_category: "notifications/fetch/creator/comms/category",
  get_user_comms_category: "notifications/fetch/user/comms/category",
  get_creator_comms: "notifications/exly/creator/list",
  get_user_comms: "notifications/exly/user/list",
  create_onboarding_schedule: "host/v2/listing/create",
  get_reorder_listings: "host/get/reorder/listings",
  update_sku_name: "host/update/listing/typename",
  update_plan_heading: "host/update/plan/heading",
  update_keyword_name: "host/update/creator/keyword",
  delete_keyword: "host/delete/creator/keyword",
  update_listing_priority: "host/update/listing/priority",
  update_plan_priority: "host/update/plan_type/priority",
  update_prepend_plan: "host/update/prepend_plan",
  update_keyword_category: "host/update/categorize/by/keyword",
  update_sku_priority: "host/update/listing/type/order",
  update_keyword_priority: "host/update/creator/keyword/sequence",
  fetch_keywords: "host/get/creator/keyword",
  add_keyword: "host/add/creator/keyword",
  add_keyword_listing: "host/add/keywork/listings",
  add_keyword_plans: "host/add/keywork/plans",
  remove_keyword_listing: "host/delete/keywork/listings",
  remove_keyword_plan: "host/delete/keywork/plans",
  update_listing_category: "host/update/creator/listing/category",
  get_selected_reorder_type: "host/get/creator/listing/category",
  add_custom_domain: "host/add/custom/domain",
  get_custom_domain: "host/fetch/custom/domains",
  transaction_list: "payments/exly/transactions/list",
  manage_subscriptions_list: "payments/exly/subscription/list",
  refund_history: "payments/refunds/history",
  subscription_list: "host/creator/subscription/list",
  payouts_list: "host/report/payouts",
  delete_custom_domain: "host/delete/custom/domain",
  set_domain_servers_linked: "host/mark/servers/linked",
  custom_domain_linking: "host/start/custom/linking",
  config_upload_reload_download_domain: "host/update/download/config",
  record_referral_clicks: "users/record/referral/clicks",
  get_hsn_data: "host/hsn/listing",
  delete_batch: "host/listing/batch/delete",
  get_subscription_stats: "host/v2/subscription/statistics",
  send_renewal_reminder: "notifications/exly/send/renewal/reminder",
  fetch_custom_code_listing: "host/custom/code/listing",
  marketing_creatives_library: {
    get_marketing_creatives: "host/creative/listing",
    create_marketing_creative: "host/creative/create",
    update_marketing_creative: "host/creative/update",
    get_marketing_creative_details: "host/creative/details",
  },
  subscription_opt_out: "host/subscription/optout",
  media_library: {
    get_media_library: "host/library/listing",
    update_media: "host/content/update",
    get_vdo_cipher_video_otp: "host/fetch/drm/otp",
  },
  check_google_drive_integration: "users/drive/validity",
  initiate_google_drive_validation: "users/drive/validation/initiate",
  dashboard: {
    stats: `host/statistics`,
    analytics_bar: "host/v2/analytics/bar/",
    analytics_line: "host/v2/analytics/line/",
    sessions_list: "host/sessions/list",
    analytics_event_views: "analytics/event/referer/views",
  },
  ad_creation: {
    campaigns: {
      check_campaign_exists: "marketing/ads/campaign/existency",
      list_campaigns: "marketing/ads/campaign/list",
      create_campaign: "marketing/ads/campaign/create",
      update_campaign: "marketing/ads/campaign/update",
      design_add: "marketing/ads/design/add",
      get_campaign_detail: "marketing/ads/campaign/details",
      delete_campaign: "marketing/ads/campaign/delete",
      refresh_stats: "marketing/ads/campaign/refresh",
    },
    creatives: {
      list_default_creatives: "marketing/ads/creatives/default/list",
      list_consumed_creatives: "marketing/ads/creatives/consumed/list",
    },
    personas: {
      list_default_personas: "marketing/ads/persona/default/list",
      list_creator_personas: "marketing/ads/persona/creator/list",
      location_list: "marketing/ads/location/list",
      interest_list: "marketing/ads/interest/list",
      create_persona: "marketing/ads/persona/create",
      update_persona: "marketing/ads/persona/update",
      fetch_persona_reach: "marketing/ads/persona/stats",
    },
    order: {
      payment_initiate: "marketing/ads/payment/initiate",
      transaction_details: "marketing/ads/payment/status",
    },
  },
  telegram: {
    send_telegram_otp: "users/telegram/otp/send",
    verify_telegram_otp: "users/telegram/otp/verify",
    get_telegram_channels: "users/telegram/communities?channels=1",
    get_telegram_connection_status: "users/telegram/status",
    get_telegram_listing_details: "host/listing/telegram",
    connect_telegram_with_listing: "host/sku/connect/telegram",
    get_telegram_groups: "users/telegram/communities?groups=1",
  },
  comments_reactions: {
    handle_blog_comments: "blogs/public/posts/comments",
    handle_comments: "blogs/public/comments",
    get_comments_on_recorded_content: "host/recorded/comments/list",
    add_comment_on_recorded_content: "host/recorded/comments/add",
    get_replies_in_recorded_content: "host/replies/listing",
    update_comment_on_recorded_content: "host/recorded/comments/update",
    add_reaction_on_comment_recorded_content: "host/reaction/create",
    get_reaction_listing: "host/reaction/listing",
  },
  schedule_management: {
    get_creator_booked_sessions: "host/booked/sessions",
    get_creator_open_appointment_sessions: "host/open/sessions",
    creator_schedule_session: "host/schedule/creator/session",
    creator_reschedule_session: "host/reschedule/creator/session",
    get_booked_sessions: "host/creator/booked/sessions",
    get_creator_blocked_slots: "host/v2/unavailability/list",
    delete_creator_unavailability: "host/v2/unavailability/delete",
    add_creator_unavailability: "host/v2/unavailability/add",
    toggle_blocking_from_google_calendar: "users/update/calendar/backsync",
    set_schedule_status:
      "payments/exly/transactions/update/customerScheduleStatus",
  },
  get_affiliate_dropdown_data: "host/affliate/url/options",
  attendance: {
    get_attendance_list: "host/get/attendance/list",
    get_attendance_list_of_listing: "host/get/listing/attendance",
    get_attendance_of_customer_of_listing: "host/get/user/attendance/activity",
    send_reminder_to_customer_to_join: "host/send/attendance/reminder/comm",
  },
  listing_miscellaneous_data: {
    get: "host/listing/miscellaneous/data",
    create: "host/listing/miscellaneous/data/create",
    update: "host/listing/miscellaneous/data/update",
    delete: "host/listing/miscellaneous/data/delete",
  },
  create_promotion_coupon: "payments/exly/promotion/coupon/create",
  organization: {
    fetch_role_permissions: "users/role/permissions/get",
    fetch_role_default_permissions: "users/role/default/permissions/get",
    fetch_users_saved_permissions: "users/org/permissions/get",
    update_member_permissions: "users/org/permissions/create",
    update_role_permissions: "users/default/permissions/create",
  },
  creator_subscription: {
    get_creator_subscription: "payments/feature/subscription/list",
    get_subscription_feature_mapping: "users/creator/plan/details",
  },
  segments: {
    get_segment_conditions: "host/segment/conditions",
    get_segment_list: "host/segments/list",
    get_segment_people_customer: "host/segment/customers/people",
    get_segment_people_leads: "host/segment/leads/people",
    duplicate_segment: "host/segment/duplicate",
    delete_segment: "host/segment/delete",
    create_segments: "host/segment/create",
    update_segments: "host/segment/update",
  },
  onboarding_flag: {
    get: "host/flag/get",
    update: "host/flag/update",
  },
  seo: {
    get_preview_data: "marketing/seo/google/results/data/details",
    get_sample_descriptions: "marketing/seo/google/descriptions",
    update_preview_data: "marketing/seo/google/results/data/update",
    add_preview_data: "marketing/seo/google/results/data/add",
    get_marketing_score: "marketing/seo/score",
    get_google_web_master_state: "marketing/seo/google/report/state",
    get_google_search_report: "marketing/seo/google/report/fetch",
    update_meta_data: "marketing/seo/google/meta/update",
    start_google_search_sync: "marketing/seo/google/website/connect",
    get_feature_access: "marketing/seo/features",
  },
  creator_miscellaneous_data: {
    create: "users/miscellaneous/data/create",
    update: "users/miscellaneous/data/update",
    delete: "users/miscellaneous/data/delete",
    get: "users/miscellaneous/data",
  },

  feedbacks: {
    fetch_feedback_questions: "users/exly/feedback/questions",
    fetch_feedbacks_list: "host/exly/feedback/list",
    fetch_published_feedbacks: "host/listing/reviews",
    fetch_listing_feedbacks: "host/exly/listing/feedback/list",
  },

  listingSpecificCommunication: {
    listing_specific_base: "notifications/list/listing",
    listing_comm_email_list: "notifications/list/listing/email/comm",
    listing_comm_variables: "content/email/variables/list",
    listing_comm_whatsapp_list: "notifications/list/listing/whatsapp/comm",
    get_sender_details: "notifications/get/comm/sender/data/",
    update_sender_details: "notifications/update/comm/sender/details",
    comm_drip: "host/create/listing/comm",
    whatsapp_comm_update: "notifications/update/whatsapp/drip",
    comm_update: "notifications/update/email/drip",
    comm_delete: "host/delete/listing/comm",
    comm_update_schedule: "host/update/listing/comm",
    get_offering_details_sessions: "host/sessions/list",
    get_offering_details_sessions_by_id: "host/sessions/list/:listing_uuid",

    get_whatsapp_template_data: "notifications/get/whatsapp/drip/",
  },

  analytics: {
    fetch_slider_data: "host/v2/statistics",
    motivation: "host/v2/statistics/motivation",
    fetch_graph_data: "host/v2/statistics/line",
    fetch_overall_data: "host/v2/statistics/overall",
  },
  google_oauth: {
    get_google_service_validity: "users/google/validity",
    initiate_google_validation: "users/google/validation/initiate",
  },
  crm: {
    create_lead: "host/lead/import/create",
    get_all_tags: "host/tags/list",
    get_tags: "host/tags/assigned/list",
    update_note: "host/notes/update",
    delete_note: "host/notes/delete",
    add_tag: "host/tags/create",
    delete_tag: "host/tags/delete",
    update_tag: "host/tags/update",
    assign_tag: "host/tags/assign",
    unassign_tag: "host/tags/unassign",
    lead_filter: "host/v2/interest/list",
    customer_filter: "payments/exly/customers/list/v2",
    get_columns: "host/columns/manage/list",
    update_columns: "host/columns/manage/update",
    create_columns: "host/columns/manage/create",
    delete_columns: "host/columns/manage/delete",
    get_logs: "host/logs/list",
  },

  shiprocket: {
    connect: "users/shiprocket/connect",
    validity: "users/shiprocket/validity",
    get_addresses: "users/shiprocket/address/get",
    add_address: "users/shiprocket/address/add",
  },

  multipart_upload_s3: {
    create_multipart_request: "host/create/multipart/request",
    get_pre_signed_urls: "host/get/part/presigned/url",
    complete_multipart_request: "host/complete/multipart/request",
  },
  integrations: {
    generate_wordpress_token: "users/integrations/wordpress/update",
    get_wordpress_token: "users/integrations/wordpress/get",
    get_integration_status: "users/integrations/states",
    save_pabbly_payout_url: "users/integrations/pabbly/update",
    get_pabbly_payout: "users/integrations/pabbly/get",
    get_pixel_and_analytics_token: "host/tracking/tokens/get",
    get_shiprocket_details: "users/integrations/shiprocket/get",
    get_zapier_details: "users/get/zapier/integration",
  },
  order_bumps: {
    update_orderBump: "host/upsell/orderbump/update",
    update_upsell: "host/upsell/map/update",
    get_orderBumps: "host/upsell",
    analytics_funnel: "host/funnel/analytics",
    customer_payment_details: "host/funnel/listing/customers/list",
    plan_analytics_funnel: "host/dropdown/plan/analytics/list",
  },
  whatsappCommunityGroupLink: {
    addWhatsappGroup: "host/add/whatsapp/community/link",
    getWhatsappGroups: "host/get/whatsapp/community/link",
    updateWhatsappGroups: "host/update/whatsapp/community/link",
    deleteWhatsappGroups: "host/delete/whatsapp/community/link",
  },
  vdocipher: {
    upload_creds: "host/get/vdociper/upload/credentials",
    otp: "host/generate/vdocipher/otp",
    status: "host/get/vdocipher/video/status",
  },
  custom_columns: {
    get_custom_columns: "users/get/custom/column",
    post_custom_column_data: "users/create/column/data",
  },
};

export default api;
